<template>
    <header>
        <img alt="Magazine Logo" src="logo_home.png">
    </header>
    <section>
        <router-view />
    </section>
</template>

<style lang="scss">
    #app {
        font-family: 'Open Sans', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #333;
        background: #fff;
        font-size: 16px;
        padding-bottom: 20px;
    }

    header {
        margin-top: 20px;
        padding-bottom: 10px;

        img
        {
            width: 200px;
        }

        h1{
            font-size: 1.2rem;
        }
    }


    .cta, .overlay-wrapper .dialog a.cta {
        cursor: pointer;
        padding: 15px 50px;
        color: #fff;
        background: #00933e;
        font-weight: bold;
        border: 0;
        border-radius: 3px;
        color: #fff;
        text-decoration: none;
        font-size: 12px;
    }


    .overlay-wrapper {
        position: fixed; /* Sit on top of the page content */
        width: 100%; /* Full width (cover the whole page) */
        height: 100%; /* Full height (cover the whole page) */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7); /* Black background with opacity */
        z-index: 200; /* Specify a stack order in case you're using a different order for other elements */
        display: flex;
        justify-content: center;
        align-items: center;
        
        .dialog
        {
            color: #000;
            text-align: center;
            display: flex;
            flex-direction: column;
            background: #fff;
            min-width: 200px;
            max-width: 450px;
            border-radius: 2px;
            margin: 50px 30px;
            padding: 25px;
            max-height: 90vh;
            overflow-y: auto;


            h3 {
                margin-top: 10px;
                margin-bottom: 10px;
                font-weight: 600;
                font-size: 16px;
            }

            p {
                line-height: 26px;
                margin:0 0 10px 0;
            }

            .small{
                font-size: 12px;
                line-height: 20px;
                margin-top: 20px;
            }

            button {
                background: none;
                border: none;
                color: #000;
                font-size: 20px;
                align-self: flex-end;
            }

            a {
                cursor: pointer;
                text-decoration: underline;
                font-weight: 700;
                color: #222;
                margin: 5px 0 15px 0;
            }

            .cta {
                max-width: 80%;
                align-self:center;
            }
        }
    }


</style>

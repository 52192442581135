<template>
    <div class="star-rating">
        <label class="star-rating__star"
               v-for="rating in ratings"
               :class="{'is-selected': ((currentValue >= rating) && currentValue != null), 'is-disabled': disabled}"
               v-on:click="set(rating)"
               v-on:mouseover="star_over(rating)"
               v-on:mouseout="star_out"
               v-bind:key="rating">
            <input class="star-rating star-rating__checkbox" type="radio" :value="rating" :name="name"
                   v-model="currentValue"
                   :disabled="disabled">&#9733;
        </label>
    </div>

</template>
<script>
    export default {
        name: 'StarRating',
        props: {
            'name': String,
            'modelValue': null,
            'id': String,
            'disabled': Boolean,
            'required': Boolean
        },
        emits: ['update:modelValue'],
        data: function () {
            return {
                temp_value: null,
                ratings: [1, 2, 3, 4, 5],
                currentValue: this.modelValue
            };
        },
        methods: {
            star_over(index) {
                if (!this.disabled) {
                    this.temp_value = this.currentValue;

                    return this.currentValue = index;
                }
            },
            star_out() {
                if (!this.disabled) {
                    return this.currentValue = this.temp_value;
                }
            },
            set: function (value) {
                if (!this.disabled) {
                    this.temp_value = value;
                    this.$emit('update:modelValue', value)
                    return this.currentValue = value;
                    //need to emit the value
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .star-rating__checkbox {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
    }

    .star-rating__star {
        display: inline-block;
        padding: 3px;
        vertical-align: middle;
        line-height: 1;
        color: #ababab;
        transition: color 0.2s ease-out;
        font-size: 3em;
    }

    .star-rating__star:hover {
        cursor: pointer;
    }

    .star-rating__star.is-selected {
        color: #ffd700;
    }

    .star-rating__star.is-disabled:hover {
        cursor: default;
    }



    @media (max-device-width: 500px) {
        .star-rating__star {
            font-size: 2.2em;
        }
    }
 

</style>

<template>
    <div class="home" v-if="Title">
        <p class="bold">Thank you for rating {{Title}}</p>
        <section class="starSection">
            <img :src="CoverImageSized" v-if="CoverImage" />
            <star-rating-awesome :modelValue="Form.Score" @update:modelValue="Form.Score = $event"></star-rating-awesome>
        </section>
        <section class="commentReview">
            <p class="bold">Would you be happy to add a short review?</p>

            <textarea :placeholder="'What do you like about ' + Title + '?'" v-model="Form.Body"></textarea>

            <label for="visibleToOtherCustomers" class="reviewCanBeVisible">
                <input id="visibleToOtherCustomers" type="checkbox" v-model="Form.CanBePublic" />
                <span>This review can be visible to other customers</span>
            </label>

            <div v-if="Form.CanBePublic" class="displayNameBox">
                <label>Review display name:</label>
                <input type="text" v-model="Form.DisplayName" />
            </div>


            <p v-if="validationMessage.length > 0" class="validation-error">{{ validationMessage }} </p>

            <button type="submit" class="cta" v-on:click="submit">Submit</button>
        </section>

        <div class="overlay-wrapper" v-if="showSuccessDialog && this.Form.Score >= 4">
            <div class="dialog">
                <h3>Thank you for your title review</h3>
                <p>
                    We'd love to know if you had a good experience ordering from magazine.co.uk and would like to
                    invite you to rate us on Trustpilot.
                </p>
                <a :href="trustpilotUrl" class="cta">REVIEW MAGAZINE.CO.UK</a>
                <p class="small">
                    Alternatively, you can now close this window, or if we can be of any further assistance with
                    your order then please don't hesitate to contact us at <a href="mailto:enquiries@magazine.co.uk">enquiries@magazine.co.uk</a> and we will be happy to help.
                </p>
            </div>
        </div>

        <div class="overlay-wrapper" v-if="showSuccessDialog && this.Form.Score <= 3">
            <div class="dialog">
                <h3>Thank you for your title review</h3>
                <p>Click here to return to <a href="http://magazine.co.uk">magazine.co.uk</a>. Alternatively you can close this window.</p>
            </div>
        </div>

        <div class="overlay-wrapper" v-if="showErrorDialog">
            <div class="dialog">
                <p>{{ errorMessage }} </p>
            </div>
        </div>
    </div>


</template>

<script>
    import axios from "axios";
    import StarRatingAwesome from '@/components/StarRating.vue'

    export default {
        name: 'Home',
        components: {
            StarRatingAwesome
        },
        data() {
            return {
                SubmitReviewEndpoint: process.env.VUE_APP_REVIEW_SITE_API_URL,
                Title: null,
                CoverImage: null,
                SubmitQuery: null,

                trustpilotUrl: "",
                validationMessage: "",

                Form: {
                    Body: "",
                    Score: null,
                    DisplayName: "Steve",
                    Subject: "",
                    CanBePublic: false
                },
                showTrustPilotReviewDialog: false,
                showSuccessDialog: false,
                showErrorDialog: false,
                errorMessage: ""
            };
        },
        mounted() {
            //get the querystring data

            //site=1&user=1245&order=1234&product=1234&rating=1&title=National Geographic Traveller&name=Steve&cover=https://magazine.imgix.net/catalog/product/f/a/family_tree.jpg&AuthorisationToken=REALHASHVALUEHERE
            this.SubmitQuery = window.location.search;

            //get data to display the page!
            this.CoverImage = this.$route.query.cover;
            this.Title = this.$route.query.title;

            //form data
            this.Form.DisplayName = this.$route.query.name;

            if (this.$route.query.rating && this.$route.query.rating.length > 0) {

                this.Form.Score = parseInt(this.$route.query.rating);
                if (isNaN(this.Form.Score) && this.$route.query.rating)
                    this.Form.Score = this.$route.query.rating.length;

                //only do inital submit of rating if a score was passed
                this.initalSubmit();
            }

        },
        methods: {
            submit() {
                

                if (this.isValid()) {
                    var self = this;
                    var endpoint = this.SubmitReviewEndpoint + this.SubmitQuery;
                    axios
                        .post(endpoint, this.SubmitData)
                        .then(() => {
                            self.showSuccessDialog = this.trustpilotEnabled;
                        })
                        .catch(function (error) {
                            self.errorMessage = "Failure: " + error;
                        });
                }
                
            },
            isValid() {
                this.validationMessage = "";

                var REQUIRED_BODY_LENGTH = 50;

                //review required 
                if (this.Form.Body == null || this.Form.Body.length < REQUIRED_BODY_LENGTH) {

                    this.validationMessage = this.Form.Body.length > 0 ? "Review is only " + this.Form.Body.length + " characters long. Must be at least " + REQUIRED_BODY_LENGTH + " characters"
                        : "Please enter a review before submitting";
                    return false;
                }

                //display name required when public
                if (this.Form.CanBePublic && (this.Form.DisplayName == null || this.Form.DisplayName.length < 1)) {
                    this.validationMessage = "Display name is required for public reviews";
                    return false;
                }

                return true;
            },
            initalSubmit() {
                //just submitting the score data
                var data = {
                    Score: this.Form.Score * 2
                };
                var self = this;
                var endpoint = this.SubmitReviewEndpoint + this.SubmitQuery; //fix url
                axios
                    .post(endpoint, data)
                    .then(response => {
                        self.trustpilotUrl = response.data.trustpilotUrl;
                    })
                    .catch(function (error) {
                        self.errorMessage = "Something went strangely wrong authorisation error: " + error;
                    });
            }
        },
        computed: {
            CoverImageSized() {
                return this.CoverImage + "?width=125";
            },
            SubmitData() {
                //submitting takes a score of 1-10 so we need to submit that!
                return {
                    Score: this.Form.Score * 2,
                    DisplayName: this.Form.DisplayName,
                    Subject: this.Form.Subject,
                    Body: this.Form.Body,
                    CanBePublic: this.Form.CanBePublic
                }
            },
            trustpilotEnabled() {
                return this.trustpilotUrl && this.trustpilotUrl != "";
            }
        }
    }
</script>

<style lang="scss">
    .bold{
        font-weight:600;
    }
    .home {
        max-width: 600px;
        margin: 0 auto;
    }

    header {
        border-bottom: 5px solid #eee;
    }

    .starSection {
        padding: 15px;
        background: #eee;
        img
        {
            max-width: 125px;
        }
    }

    .validation-error{
        color: red;
    }

    input[type="text"],
    textarea {
        font-family: 'Open Sans', sans-serif;
        border-radius: 3px;
        border: 1px solid #ccc;
        padding: 10px;
        font-size: 16px;
    }

    textarea {
        align-self: stretch;
        height: 100px;
        margin-bottom: 10px;
    }

    .commentReview {
        display: flex;
        flex-direction: column;

        button{
            margin-top: 20px;
            align-self: center;
            min-width: 70%;
            max-width: 80%;
            text-transform: uppercase;
        }

        button:hover{
            background:#166616;
        }

        .reviewCanBeVisible {
            align-self: flex-start;
            span{
                    margin-left: 5px;
            }
        }

        .displayNameBox {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            input
            {
                flex-grow: 1;
            }

            label {
                vertical-align: middle;
                line-height: 37px;
                margin-right: 20px;
            }
        }


        @media (max-device-width: 500px) {
            .displayNameBox {
                flex-direction: column;

                label {
                    margin-right: 0;
                }
            }

        }
    }

    @media (max-device-width: 500px) {

        .starSection {
            padding: 0px;
            background: none;
            
        }
    }


</style>